/* cyrillic-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_b5b299';
  font-style: normal;
  font-weight: 200 800;
  font-display: optional;
  src: url(/_next/static/media/eed6db14ac3b93a0.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* vietnamese */
@font-face {
  font-family: '__Plus_Jakarta_Sans_b5b299';
  font-style: normal;
  font-weight: 200 800;
  font-display: optional;
  src: url(/_next/static/media/65053818c3abcf97.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Plus_Jakarta_Sans_b5b299';
  font-style: normal;
  font-weight: 200 800;
  font-display: optional;
  src: url(/_next/static/media/034043092db1e233.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Plus_Jakarta_Sans_b5b299';
  font-style: normal;
  font-weight: 200 800;
  font-display: optional;
  src: url(/_next/static/media/2b3f1035ed87a788.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Plus_Jakarta_Sans_Fallback_b5b299';src: local("Arial");ascent-override: 98.16%;descent-override: 20.99%;line-gap-override: 0.00%;size-adjust: 105.75%
}.__className_b5b299 {font-family: '__Plus_Jakarta_Sans_b5b299', '__Plus_Jakarta_Sans_Fallback_b5b299';font-style: normal
}

/* cyrillic */
@font-face {
  font-family: '__Playfair_Display_b2d4df';
  font-style: normal;
  font-weight: 400 900;
  font-display: optional;
  src: url(/_next/static/media/07dbeab371bd23e5.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Playfair_Display_b2d4df';
  font-style: normal;
  font-weight: 400 900;
  font-display: optional;
  src: url(/_next/static/media/351a28414a1c9800.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Playfair_Display_b2d4df';
  font-style: normal;
  font-weight: 400 900;
  font-display: optional;
  src: url(/_next/static/media/c30f71787fcda0f2.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Playfair_Display_b2d4df';
  font-style: normal;
  font-weight: 400 900;
  font-display: optional;
  src: url(/_next/static/media/c6908836934a19a7.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Playfair_Display_Fallback_b2d4df';src: local("Times New Roman");ascent-override: 97.38%;descent-override: 22.59%;line-gap-override: 0.00%;size-adjust: 111.11%
}.__className_b2d4df {font-family: '__Playfair_Display_b2d4df', '__Playfair_Display_Fallback_b2d4df';font-style: normal
}

